import { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import { Autocomplete, Box, Button, Grid, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { Download, Search } from '@mui/icons-material';
import TableLoader from '../GlobalComponents/TableLoader';

interface EmitterData {
    emitter: string;
    expense: number;
    expenseInDollar: number;
    algorithm: string;
}

interface WorkerProps {
    fromDate: Date | null;
    setFromDate: (date: Date | null) => void;
    toDate: Date | null;
    setToDate: (date: Date | null) => void;
    searchTrigger: boolean;
}

const Emitter: React.FC<WorkerProps> = ({ fromDate, setFromDate, toDate, setToDate, searchTrigger }) => {
    const [searchTerm, setSearchTerm] = useState<string[]>([]);
    const [rows, setRows] = useState<EmitterData[]>([]);
    const [searchOptions, setSearchOptions] = useState([]);
    const [tableLoader, setTableLoader] = useState(false)

    useEffect(() => {
        fetchEmitterList();
    }, []);

    useEffect(() => {
        handleSearch();
    }, [searchTrigger]);

    const fetchEmitterList = () => {
        axios.get(API_PATH + '/finance/report/emitterlist', getAxiosConfig())
            .then(response => {
                setSearchOptions(response.data);
            })
            .catch(error => {
                console.error("Error fetching emitter list:", error);
            });
    };

    const fetchEmitterData = (fromTime: string, toTime: string, ids: string[]) => {
        setTableLoader(true)

        axios.post(API_PATH + '/finance/report/emitter', {
            ids,
            fromTime,
            toTime,
        }, getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setTableLoader(false)
            })
            .catch(error => {
                console.error("Error fetching emitter data:", error);
                setTableLoader(false)

            });
    };

    const formatDate = (date: Date | null) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };



    const handleDownloadCSV = () => {
        const csvRows = [
            columns.map(col => col.header).join(','),
            ...rows.map(row => {
                return columns.map(col => {
                    const value = row[col.accessorKey as keyof EmitterData];
                    if (col.accessorKey === 'expenseInDollar') {
                        return row.expenseInDollar || '0';
                    }
                    return value !== undefined ? value : '';
                }).join(',');
            })
        ];

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `worker_data_${formatDate(new Date())}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    };

    const totalExpenseBtc = useMemo(
        () => rows.reduce((acc, curr) => acc + (curr.expense || 0), 0),
        [rows],
    );
    const totalExpenseDollar = useMemo(
        () => rows.reduce((acc, curr) => acc + (curr.expenseInDollar || 0), 0),
        [rows],
    );

    const columns = useMemo<MRT_ColumnDef<EmitterData>[]>(
        () => [
            {
                accessorKey: 'emitter',
                header: 'Emitter',
                size: 150,
            },
            {
                accessorKey: 'expense',
                header: 'Expense (BTC)',
                size: 150,
                Cell: ({ row }) => `${Number(row.original.expense).toFixed(8)}`,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {totalExpenseBtc?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 10,
                            })}
                        </Box>
                    </Stack>
                )
            },
            {
                accessorKey: 'expenseInDollar',
                header: 'Expense ($)',
                size: 150,
                Cell: ({ row }) => `${Number(row.original.expenseInDollar).toFixed(8)}`,
                Footer: () => (
                    <Stack>
                        <Box color="warning.main">
                            {totalExpenseDollar?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 10,
                            })}
                        </Box>
                    </Stack>
                )
            },
            {
                accessorKey: 'algorithm',
                header: 'Algorithm',
                size: 150,
            },
        ],
        [totalExpenseBtc, totalExpenseDollar],
    );


    const handleSearch = () => {
        fetchEmitterData(formatDate(fromDate), formatDate(toDate), searchTerm);
    };
    return (
        <>
            <Grid
                container
                spacing={1}
                sx={{
                    marginBottom: "10px",
                    flexDirection: { xs: 'column', sm: 'row' },

                }}
                alignItems="end"
            >
                <Grid
                    item
                    xs={12}
                    sm="auto"
                    sx={{ width: '100%', minWidth: '300px' }}
                >
                    <Autocomplete
                        multiple
                        options={searchOptions}
                        value={searchTerm}
                        onChange={(event, newValue) => setSearchTerm(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search"
                                size="small"
                                fullWidth
                            />
                        )}

                    />
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    sm="auto"
                    spacing={1}
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: { sm: 'flex-end' },
                        alignItems: "center",
                    }}
                >
                    <Grid item xs={12} sm="auto" sx={{ width: '100%' }}>
                        <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={handleSearch}>
                            <Search />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto" sx={{ width: '100%' }}>
                        <Button variant="contained" sx={{ paddingY: 1 }} size='small' onClick={handleDownloadCSV} >
                            <Download />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {tableLoader ?
                <TableLoader /> :
                <MaterialReactTable
                    columns={columns}
                    data={rows}
                />
            }
        </>
    );
};

export default Emitter;
