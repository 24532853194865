import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useState } from "react";
import TableLoader from "../GlobalComponents/TableLoader";
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SelectedDataItem } from "../Machines/types";
import DynamicModal from "./DynamicModal";
import { TransactionData } from "./PaymentTypes";

interface FilterBlockProps {
    data:TransactionData[];
}

type Order = 'asc' | 'desc';

const PaymentHistoryTable: React.FC<FilterBlockProps> = ({ data }) => {

    const [loading, setLoading] = useState(false);
    const [errorText, setError] = useState<string | null>(null);
    const [rows, setRows] = useState<TransactionData[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('userName');

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<string | null>(null);
    const [selectOpen, setSelectOpen] = useState(false)


    const handleOpenModal = (title: string, content: string | null) => {
        setModalTitle(title);
        setModalContent(content);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setModalContent(null);
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const parseDate = (dateString: string | null | undefined): number => {
        return dateString ? new Date(dateString).getTime() : 0;
    };


    const sortedRows = data.slice().sort((a, b) => {
        let aValue: string | number | null = null;
        let bValue: string | number | null = null;

        switch (orderBy) {
            case 'userName':
                aValue = a.rewardTables[0]?.account?.userName || '';
                bValue = b.rewardTables[0]?.account?.userName || '';
                break;
            case 'amount':
                aValue = a.amount;
                bValue = b.amount;
                break;
            case 'coin':
                aValue = a.rewardTables[0]?.coin || '';
                bValue = b.rewardTables[0]?.coin || '';
                break;
            case 'transactionStatus':
                aValue = a.transactionStatus;
                bValue = b.transactionStatus;
                break;
            case 'createdTime':
                aValue = parseDate(a.createdTime);
                bValue = parseDate(b.createdTime);
                break;
            case 'transactionTime':
                aValue = parseDate(a.transactionTime);
                bValue = parseDate(b.transactionTime);
                break;
            default:
                return 0;
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else {
            return 0;
        }
    });



    function formatDateTime(isoDateString: string): string {
        const date = new Date(isoDateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} , ${hours}:${minutes}:${seconds}`;
    }



    return (
        <>

            <Paper sx={{ padding: "16px" }}>
                {loading ?
                    <TableLoader /> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'dummy'}
                                        direction={orderBy === 'dummy' ? order : 'asc'}
                                        onClick={() => handleRequestSort('dummy')}
                                    >
                                        Tag
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'userName'}
                                        direction={orderBy === 'userName' ? order : 'asc'}
                                        onClick={() => handleRequestSort('userName')}
                                    >
                                        User
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'amount'}
                                        direction={orderBy === 'amount' ? order : 'asc'}
                                        onClick={() => handleRequestSort('amount')}
                                    >
                                        Amount ($)
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'coin'}
                                        direction={orderBy === 'coin' ? order : 'asc'}
                                        onClick={() => handleRequestSort('coin')}
                                    >
                                        Coin
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'transactionStatus'}
                                        direction={orderBy === 'transactionStatus' ? order : 'asc'}
                                        onClick={() => handleRequestSort('transactionStatus')}
                                    >
                                        Transaction Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'createdTime'}
                                        direction={orderBy === 'createdTime' ? order : 'asc'}
                                        onClick={() => handleRequestSort('createdTime')}
                                    >
                                        Created Time
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'transactionTime'}
                                        direction={orderBy === 'transactionTime' ? order : 'asc'}
                                        onClick={() => handleRequestSort('transactionTime')}
                                    >
                                        Transaction Time
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedRows.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Box textAlign="center">No Data Available</Box>
                                    </TableCell>
                                </TableRow>
                            )}
                            {sortedRows.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {detail?.dummy !== undefined ? (
                                            <span
                                                style={{
                                                    width: 10,
                                                    height: 10,
                                                    borderRadius: '50%',
                                                    backgroundColor: detail.dummy ? 'red' : 'green',
                                                    display: 'inline-block',
                                                    marginRight: 8,
                                                }}
                                            ></span>
                                        ) : (
                                            "N/A"
                                        )}
                                    </TableCell>
                                    <TableCell>{detail?.rewardTables[0]?.account?.userName || "N/A"}</TableCell>
                                    <TableCell>{detail?.amount}</TableCell>
                                    <TableCell>{detail?.rewardTables[0]?.coin || "N/A"}</TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <span>{detail?.transactionStatus}</span>
                                            {detail?.networkTransactionId && (
                                                <InfoIcon
                                                    onClick={() => handleOpenModal("Network Transaction ID", detail?.networkTransactionId)}
                                                    sx={{ color: '#1977d3', mr: 1, cursor: 'pointer' }}
                                                />
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{formatDateTime(detail?.createdTime)}</TableCell>
                                    <TableCell>{detail?.transactionTime ? formatDateTime(detail?.transactionTime) : '----'}</TableCell>
                                    <TableCell>
                                        {detail?.rawError && (
                                            <WarningAmberIcon
                                                onClick={() => handleOpenModal("Error Details", detail?.rawError)}
                                                sx={{ color: '#FF0000', mr: 1, cursor: 'pointer' }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </Paper>
            <DynamicModal
                open={open}
                modalTitle={modalTitle}
                modalContent={modalContent}
                handleCloseModal={handleCloseModal}
            />
        </>
    );
};

export default PaymentHistoryTable;
